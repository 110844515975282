import Vue from 'vue'
import store from '../store'

import VueRouter from 'vue-router'
import RegistrationPage from '../views/RegistrationPage'
import TwoFASetupPage from '../views/TwoFASetupPage'
import LoginPage from '../views/LoginPage'
import TwoFAPage from '../views/TwoFAPage'
import VerifyEmailPage from '../views/VerifyEmailPage'
import ForgotPasswordPage from '../views/ForgotPasswordPage'
import ResetPasswordPage from '../views/ResetPasswordPage'
import AccountSuspendedPage from '../views/AccountSuspendedPage'
import ConsentPage from '../views/ConsentPage'
import SubAdvisorConsentPage from '../views/SubAdvisorConsentPage'
import SubAdvisorConsentDisagreePage from '../views/SubAdvisorConsentPage/Disagree'
import DcSurveyPage from '../views/DataCapture/SurveyPage'
import MyDocumentsPage from '../views/MyDocumentsPage'
import DcClientMaintenancePage from '../views/DataCapture/ClientMaintenancePage'
import UnauthorizedPage from '../views/Errors/UnauthorizedPage'
import GenericErrorPage from '../views/Errors/GenericErrorPage'
import Home from '../views/Home'

Vue.use(VueRouter)

const redirectOnAuth = async (to, from, next) => {
  //* Checking for consent status
  await store.dispatch('consent/initUserConsent')
  if (store.getters['consent/userConsentRequired']) {
    validateBeforeNavigation(to, from, next, 'ConsentPage')
    return
  } else if (to.name === 'ConsentPage') {
    next({ name: 'Home' })
    return
  }

  if (store.getters['consent/subAdvisorConsentRequired']) {
    validateBeforeNavigation(to, from, next, 'SubAdvisorConsentPage')
    return
  } else if (to.name === 'SubAdvisorConsentPage') {
    next({ name: 'Home' })
    return
  }

  //* Checking for survey status
  await store.dispatch('user/getSurveyStatus')
  if (to.name === 'Home') {
    if (!store.getters['user/isActiveClient']) {
      next({ name: 'DcSurveyPage' })
    }
    next()
  } else {
    next()
  }
}

export const routes = [
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationPage,
    meta: {
      requiresAuth: false
    },
    props: route => ({ query: route.query.q })
  },
  {
    path: '/2fa-setup',
    name: 'TwoFASetupPage',
    component: TwoFASetupPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/login',
    name: 'loginPage',
    component: LoginPage,
    alias: '/',
    meta: {
      requiresAuth: false,
      showHeader: false
    }
  },
  {
    path: '/2fa',
    name: 'TwoFAPage',
    component: TwoFAPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPasswordPage',
    component: ForgotPasswordPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordPage',
    component: ResetPasswordPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/verify-email',
    name: 'VerifyEmailPage',
    component: VerifyEmailPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/account-suspended',
    name: 'AccountSuspendedPage',
    component: AccountSuspendedPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/grant',
    name: 'GrantPage',
    component: LoginPage,
    meta: {
      requiresAuth: false,
      showHeader: false
    }
  },
  {
    path: '/consent',
    name: 'ConsentPage',
    component: ConsentPage,
    meta: { auth: true },
    beforeEnter: redirectOnAuth
  },
  {
    path: '/sub-advisor-consent',
    name: 'SubAdvisorConsentPage',
    component: SubAdvisorConsentPage,
    meta: { auth: true },
    beforeEnter: redirectOnAuth
  },
  {
    path: '/sub-advisor-consent/disagree',
    name: 'SubAdvisorConsentDisagreePage',
    component: SubAdvisorConsentDisagreePage,
    meta: { auth: true }
  },
  {
    path: '/survey',
    name: 'DcSurveyPage',
    component: DcSurveyPage,
    meta: { auth: true },
    beforeEnter: redirectOnAuth
  },
  {
    path: '/client-maintenance',
    name: 'DcClientMaintenancePage',
    component: DcClientMaintenancePage,
    meta: {
      auth: true,
      canAccess: (store) => store.getters['user/clientMaintenanceEnabled'] && store.getters['user/isActiveClient']
    },
    beforeEnter: redirectOnAuth
  },
  {
    path: '/documents',
    name: 'MyDocumentsPage',
    component: MyDocumentsPage,
    meta: { auth: true },
    beforeEnter: redirectOnAuth
  },
  {
    path: '/unauthorized',
    name: 'UnauthorizedPage',
    component: UnauthorizedPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/error',
    name: 'GenericErrorPage',
    component: GenericErrorPage,
    alias: '*',
    meta: {
      requiresAuth: false,
      showHeader: false
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    beforeEnter: redirectOnAuth,
    meta: {
      auth: true,
      showHeader: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    await store.state.authService.isAuth()
    if (store.state.authService.authenticated) {
      await store.dispatch('user/bootstrapCall')
      if (to.meta.canAccess && to.meta.canAccess(store) === false) {
        next({ path: '/unauthorized', query: { redirect: to.fullPath } })
      } else {
        next()
      }
    } else {
      store.dispatch('user/clearUserInfo')
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
})

const validateBeforeNavigation = (to, from, next, componentName) => {
  if (from.name !== componentName && to.name !== componentName) {
    next({
      name: componentName,
      query: { redirect: to.fullPath }
    })
  } else if (to.name === componentName) {
    next()
  } else {
    next(false)
  }
}

export default router
