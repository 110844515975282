<template>
  <div v-if="!loading">
    <SingleColumn md="4" offset-md="4">
      <PCard class="py-2 px-2">
        <Registration
          :name="name"
          :email="email"
          :invitation-code="invitation_code"
          :client-id="client_id"
          v-if="screen === screens.registration"
          @registered="handleRegister"
          @error="registrationError"
        />

        <template v-if="screen === screens.registered">
          <PRow class="justify-content-md-center text-center">
            <PColumn>
              <div class="subtitle bold">
                {{ $t('account_creation:already_registered') }}
              </div>
              <div class="subtitle mt-3 mb-4">
                <router-link to="/login">
                  {{ $t('login:signIn_text') }}
                </router-link>
              </div>
            </PColumn>
          </PRow>
        </template>
        <template v-if="screen === screens.expired">
          <PRow class="justify-content-md-center">
            <PColumn>
              <div class="subtitle bold">
                {{ $t('common:sorry') }}
              </div>
              <div class="subtitle mt-3 mb-4">
                {{ $t('account_creation:invite_expired') }}
              </div>
              <PButton @click="resendLink" variant="dark">
                {{ $t('account_creation:invite_request') }}
              </PButton>
            </PColumn>
          </PRow>
        </template>

        <template v-if="screen === screens.invalid">
          <PRow class="justify-content-md-center">
            <PColumn>
              <div class="subtitle bold">
                {{ $t('common:sorry') }}
              </div>
              <div class="subtitle mt-3">
                {{ $t('account_creation:invite_invalid_1') }}<br>
                {{ $t('account_creation:invite_invalid_2') }}
              </div>
            </PColumn>
          </PRow>
        </template>

        <template v-if="screen === screens.resent">
          <PRow class="justify-content-md-center text-center">
            <PColumn>
              <div class="subtitle bold">
                {{ $t('account_creation:advisor_notified') }}
              </div>
              <div class="subtitle mt-3">
                {{ $t('account_creation:invite_info') }}
              </div>
            </PColumn>
          </PRow>
        </template>
      </PCard>
    </SingleColumn>
  </div>
</template>

<script>
import { identifyUser } from '../../services/logRocket'
import { Base64 } from 'js-base64'

const screens = {
  registration: 'registration',
  registered: 'registered',
  expired: 'expired',
  invalid: 'invalid',
  resent: 'resent'
}

export const InvitationStatusEnum = {
  pending: 1,
  accepted: 2,
  expired: 3,
  invalid: 4
}

export default {
  name: 'RegistrationPage',
  data() {
    return {
      name: '',
      email: '',
      invitation_code: '',
      client_id: null,
      linkIsValid: false,
      resendSreen: false,
      screen: 'registration',
      loading: true,
      screens
    }
  },
  async mounted() {
    const { details } = this.$route.query
    if (!details) {
      this.screen = screens.invalid
      this.loading = false
      return
    }
    const decodeDetails = JSON.parse(Base64.decode(details))
    const { client_name: clientName, email, invitation_code: invCode, client_id: clientId, preferred_language: lang } = decodeDetails
    if (!clientName || !email || !invCode || !clientId) {
      this.screen = screens.invalid
      this.loading = false
      return
    }

    this.name = clientName
    this.email = email
    this.invitation_code = invCode
    this.client_id = parseInt(clientId)

    // Old links might not have the language set.
    if (lang) {
      const locizeLang = (lang === 'EN' ? 'en-CA' : 'fr-CA')
      // Change the i18n language and the local storage value.
      // Depending on the mounting order, both must be set. Otherwise,
      // it is possible that language is not properly set in the Nav bar.
      this.$i18n.i18next.changeLanguage(locizeLang)
      localStorage.currentLanguage = locizeLang
    }

    identifyUser({ client_id: this.client_id, name: this.name, email: this.email, firm: this.firm })
    try {
      // verify registration status
      const resp = await this.$store.dispatch('auth/checkRegisterStatus', {
        email: this.email,
        invitationCode: this.invitation_code,
        clientId: this.client_id,
        firm: this.firm
      })
      if (resp.status === InvitationStatusEnum.accepted) {
        this.screen = screens.registered
      } else if (resp.status === InvitationStatusEnum.expired) {
        this.screen = screens.expired
      } else if (resp.status === InvitationStatusEnum.invalid) {
        this.screen = screens.invalid
      }
    } catch (e) {
      // first time user, no record of registration process
    } finally {
      this.loading = false
    }
  },
  methods: {
    async resendLink() {
      try {
        await this.$store.dispatch('auth/requestInviteLink', {
          clientId: this.client_id, firm: this.firm
        })
        this.screen = 'resent'
      } catch (e) {
        console.error(e)
        this.showErrorToast('There was an issue requesting invite link. Please contact support.')
      }
    },
    registrationError({ error }) {
      if (error.toLowerCase().includes('expired')) {
        this.screen = screens.expired
      } else if (error.toLowerCase().includes('invalid')) {
        this.screen = screens.invalid
      } else if (error.toLowerCase().includes('generic')) {
        this.showErrorToast('There was an issue creating your account - please try again in a few minutes')
      } else if (error.toLowerCase().includes('exists')) {
        this.showErrorToast('A user with this email already exists')
      }
    },
    handleRegister() {
      this.$router.push({ name: 'TwoFASetupPage' })
    },
    showErrorToast(text) {
      this.$store.dispatch('notification/showNotification', { type: 'error', message: text })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
